.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.pagination {
  display: flex;
  justify-content: space-between;
  list-style: none;
  cursor: pointer;
  align-self: center;
  justify-content: center;
  margin-top: 50px;
}

.pagination a {
  padding: 10px;
  border-radius: 10px;
  /* border: 1px solid #A50034; */
  color: #282c34;
  background-color: #fff;
  margin: 3px;
  font-family: Noto Sans KR;
}

.pagination__link {
  font-family: Noto Sans KR;
}

.pagination__link--active a {
  color: #fff;
  background: #1c1c1c;
  margin: 5px;
}

.pagination__link--disabled a {
  color: rgb(198, 197, 202);
  /* border: 1px solid rgb(198, 197, 202); */
}

.searchbar {
  margin-bottom: 30px;
  left: 3%;
  right: 3%;
}

.searchbar > div:first-of-type {
  flex-direction: row;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* //////////////// */

.MuiPaper-root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.MuiGrid-root .MuiGrid-item {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: flex !important;
  -webkit-line-clamp: 14 !important;
  -webkit-box-orient: vertical !important;
}

.MuiBreadcrumbs-ol {
  margin-bottom: 1rem !important;
}

.MuiBreadcrumbs-li > a {
  display: flex;
  align-items: center;
}

.MuiBreadcrumbs-li > a > svg {
  margin-right: 0.5rem;
}
.MuiBreadcrumbs-li > a[name="title"] > svg,
.MuiBreadcrumbs-li > a[name="titleSelected"] > svg {
  width: 1rem;
}

.MuiBreadcrumbs-li > a[name="titleSelected"],
.MuiBreadcrumbs-li > a[name="imageViewSelected"] {
  color: black;
  font-weight: bold;
}

.MuiLink-underlineHover:hover {
  text-decoration: none !important;
}

/* 아이템들 너비를 맞추는 코드 */
div[class*="makeStyles-root-"] {
  width: -webkit-fill-available;
}

.grid__item--vertical > div > div {
  width: -webkit-fill-available;
}

/* 버튼들 */
.btns__wrapper--pdf {
  width: -webkit-fill-available;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 0 -10px;
  padding: 1rem 0rem 1rem 0.7rem;
}

.btns__wrapper--pdf > div {
  display: flex;
}
.btns__wrapper--pdf > button {
  align-self: start;
}

.button--pdf {
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid gray;
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
  color: #393737f0;
}
.button--calendar {
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid gray;
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
  color: #393737f0;
  margin-right: 0.5rem;
}

.icon--cloud {
  width: 1.2rem;
  margin-right: 0.3rem;
  color: #393737f0;
}

.icon--calendar-empty {
  width: 1.2rem;
  margin-right: 0.3rem;
  color: #393737f0;
}

.MuiAutocomplete-root {
  width: -webkit-fill-available;
}

/* accordion */
.side-nav {
  /* width: max-content; */
  /* width: 180px; */
}

/* 클릭 시 나오는 핑크 줄 없앰 */
.side-nav__items .MuiTabs-indicator {
  background-color: transparent;
}

.side-nav .MuiTab-wrapper {
  align-items: baseline;
  padding-left: 1rem;
}

.accordion {
  display: flex;
  justify-content: space-between;
}
.accordion > a {
  padding: 0;
}
.accordion > img {
  cursor: pointer;
}
.accordion .MuiTab-wrapper {
  align-items: baseline;
}

.tab__sub-item {
  display: flex;
  flex-direction: column;
}

.tab__sub-item .MuiTab-wrapper {
  align-items: baseline;
  text-align: left;
  padding-left: 2rem;
}

.side-nav .MuiTab-root {
  padding: 0 !important;
}

.clicked > span {
  font-weight: 800;
}

.hidden {
  display: none;
}

/* 상단바 */
.top-nav > .MuiTabs-root {
  box-shadow: 0 2px 4px rgb(0 0 0 / 8%), 0 4px 12px rgb(0 0 0 / 8%);
  position: sticky !important;
}

/* 클릭 시 나오는 핑크 줄 없앰 */
.top-nav .MuiTabs-indicator {
  background-color: transparent;
}

.TabPanelSub-tabs-9 {
  width: 225px !important;
}

/* header */
.header {
  padding: 1rem 0;
  position: sticky !important;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 1px 0px 0px rgb(0 0 0 / 12%) !important;
}

.logo-wrapper {
  justify-content: center;
  width: 100vw;
  color: black;
  flex-direction: column;
}
.logo-wrapper > img {
  width: 2rem;
  margin-bottom: -1rem;
}
.logo-wrapper > p {
  font-family: "Playfair Display", serif;
  font-family: "Playfair Display", serif;
  font-weight: bold;
  font-size: 4rem;
  margin: 0;
}
.logo-wrapper .MuiToolbar-root {
  position: sticky !important;
}

/* card */
.card h4 {
  cursor: pointer;
}
.card h4:hover {
  transform: scale(1.01);
}

/* 상단 메뉴 선택된 아이템 */
.MuiTab-textColorInherit.Mui-selected {
  font-weight: bolder !important;
  color: black;
  font-size: 1rem;
}